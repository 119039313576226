/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px grey;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}

::-webkit-scrollbar-thumb {
    background: #747474;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(27, 27, 27);
}

// mat-progress-bar .mat-progress-bar-buffer {
//     background: #53e296;
//   }